
import React from "react";


const CreatorsIntroducePcFc: React.FC = () => {
 
    return (
        <div className="row mt-1 bg-f9 pd-2" style={{margin:'0'}}>
            <div className="col-2">创作者</div>
            <div className="col">简介</div> 
              <div className="col">报价</div>
            <div className="col-1">区域</div>
            <div className="col-2">标签</div>
            <div className="col">作品</div>
            <div className="col"></div>
        </div>
    )
}

export default CreatorsIntroducePcFc;